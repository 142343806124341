//账单详情
<template>
  <div class="works">
    <LeftMenu current="income" />
    <div class="main">
      <el-row>
        <el-col :span="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
            <el-breadcrumb-item>账单详情</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="12" style="text-align: right"
          ><el-link type="primary" @click="$router.back()">
            返回</el-link
          ></el-col
        >
      </el-row>
      <el-divider></el-divider>
      <p class="title">账单详情</p>
      <div
        class="withdraw"
        v-if="incomeDetailobj.status == 2 || incomeDetailobj.status == 3"
      >
        <img src="../assets/withdraw.png" alt="" />
        <span>{{ incomeDetailobj.status == 2 ? "账单提现中" : "已提现" }}</span>
      </div>
      <div class="centent">
        <p>{{ incomeDetailobj.money || 0 | money }}<span>元</span></p>
        <div class="detailed">
          <ul>
            <li><span>账单名称</span>{{ incomeDetailobj.title }}</li>
            <li>
              <span>创建时间</span
              >{{ this.$timestampToString(incomeDetailobj.createTime) }}
            </li>
            <li><span>账单分类</span>{{ incomeDetailobj.type | type }}</li>
            <li><span>账单编号</span>{{ incomeDetailobj.id }}</li>
            <li><span>付款方</span>{{ incomeDetailobj.paymentCompany }}</li>
            <li>
              <span>入账时间</span
              >{{ this.$timestampToString(incomeDetailobj.updateTime) }}
            </li>
          </ul>
          <div>
            <el-button v-if="incomeDetailobj.status == 1" @click="defined"
              >申请提现</el-button
            >
            <el-button
              v-if="incomeDetailobj.status == 0"
              @click="centerDialogVisible = true"
            >
              确认账单
            </el-button>
          </div>
        </div>
      </div>
      <p class="foot-title">歌曲收益详情</p>
      <el-divider class="border-title"></el-divider>
      <!-- 表格 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="headerStyle"
      >
        <el-table-column label="歌曲名称">
          <template slot-scope="row">
            {{ row.song.name }}
          </template>
        </el-table-column>
        <el-table-column prop="role" label="权益"> </el-table-column>
        <el-table-column :formatter="moneyfixed" prop="cost" label="核销成本">
        </el-table-column>
        <el-table-column
          :formatter="moneyfixed"
          prop="deductAmount"
          label="核销预付"
        >
        </el-table-column>
        <el-table-column
          :formatter="moneyfixed"
          prop="realShareAmount"
          label="收益"
        >
        </el-table-column>
      </el-table>
      <div class="pagin">
        <el-pagination
          background
          layout="prev, pager, next,total "
          :total="totalnum || 0"
          ref="pagination"
          :current-page="num"
          @current-change="currentChange"
        >
        </el-pagination>
        <div class="input-page">
          <span>到第</span>
          <span
            ><el-input
              size="mini"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="pageinput"
            ></el-input
          ></span>
        </div>
        <button @click="paging">确认</button>
      </div>
    </div>

    <!-- 提示框 -->
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="548px"
      center
      :close-on-click-modal="false"
    >
      <span>账单一经确认不可撤回，请确认账单是否无误</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="defined">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { wallet_incomeDetail, wallet_incomeDetailCheck } from '../api/index'
import LeftMenu from '../components/LeftMenu.vue'
export default {
  components: {
    LeftMenu
  },
  data () {
    return {
      // 总条数
      totalnum: 0,
      // 当前条数
      num: 1,
      tableData: [],
      //   提示框
      centerDialogVisible: false,
      headerStyle: {
        background: '#f8f8f8',
        fontSize: '14px',
        fontFamily: 'PingFang SC, PingFang SC - Regular',
        fontWeight: 600,
        color: '#333333',
      },
      incomeDetailobj: {},
      params: {
        id: this.$route.query.id,
        pageNum: 1,
        pageSize: 10
      },
      //   分页按钮
      pageinput: 1

    }
  },
  created () {
    this.incomeDetail()
  },
  methods: {
    incomeDetail () {
      let p = this.params
      wallet_incomeDetail(p).then((res) => {
        this.incomeDetailobj = res.data.appIncomeDetailsEntity
        this.tableData = res.data.appIncomeDetailsEntity.list.list || []
        this.totalnum = res.data.appIncomeDetailsEntity.list.total || 0
      })
    },
    defined () {

      if (
        this.incomeDetailobj.status === 0
      ) {

        wallet_incomeDetailCheck(this.incomeDetailobj.id).then((res) => {
          if (res.code == 0) {
            this.$router.push('/billdetailsuccess')
          }
        })
      } else if (
        this.incomeDetailobj.status === 1
      ) {
        let p = {
          id: null,
          incomeList: [this.incomeDetailobj],
          money: this.incomeDetailobj.money,
          name: "",
          riseData: null,
          tax: this.incomeDetailobj.faxMoney,
          totalMoney: this.incomeDetailobj.totalMoney,

        }

        this.$router.push('/withdrawal/' + JSON.stringify(p))

      }

    },
    // 分页器
    currentChange (e) {
      this.params.pageNum = e
      this.pageinput = e
      this.incomeDetail()
    },
    paging () {
      this.num = Number(this.pageinput)
      this.params.pageNum = this.pageinput
      this.incomeDetail()
    },
    moneyfixed (val) {
      return val.toFixed(2)
    }


  },
  filters: {
    money (val) {
      return val.toFixed(2)
    },
    type (val) {

      switch (val) {
        case '1001601': return '账单分成'
        case '1001602': return '版税分成'
        case '1001603': return '词曲收入'
        case '1001604': return '其他'
        default: return '其他'
      }


    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/initialization.scss";
@import "../styles/works.scss";
.el-divider {
  margin-top: 12px;
  margin-bottom: 40px;
}
.title {
  font-size: 20px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #333333;
  letter-spacing: 0px;
  margin-bottom: 30px;
}
.success {
  margin-top: 186px;
  text-align: center;
  img {
    width: 100px;
    height: 100px;
  }
  .title {
    margin: 0px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #666666;
    margin-top: 24px;
  }
  .bnt {
    margin: 0px;
    margin-top: 34px;
    button {
      border: none;
      width: 124px;
      height: 40px;
      opacity: 1;
      background: #efefef;
      margin: 0px 20px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #ff004d;
    }
    button:nth-child(2) {
      color: #ffffff;
      background: #ff004d;
    }
  }
}
.withdraw {
  img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #333333;
    letter-spacing: 0px;
    margin-left: 12px;
  }
}
.centent {
  margin-top: 30px;
  box-sizing: border-box;
  padding-left: 19px;
  padding-top: 26px;
  height: 245px;
  opacity: 1;
  background: #f9f9f9;
  margin-bottom: 40px;
  > p {
    font-size: 30px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    text-align: LEFT;
    color: #333333;
    margin-bottom: 10px;
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .detailed {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    div {
      .el-button {
        width: 150px;
        height: 44px;
        opacity: 1;
        background: #ff004d;
        color: #ffffff;
        margin-right: 42px;
        margin-bottom: 16px;
      }
    }
    ul {
      li {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #999999;

        margin-bottom: 10px;
        span {
          display: inline-block;
          margin-right: 20px;
          width: 50px;
          text-align-last: justify;
          vertical-align: top;
        }
      }
    }
  }
}
.foot-title {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #333333;
  letter-spacing: 0px;
}

.border-title {
  margin-top: 17px;
  margin-bottom: 24px;
}
.pagin {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  .input-page {
    span:nth-child(1) {
      font-size: 13px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: CENTER;
      color: #555555;
      margin-right: 8px;
    }
    span:nth-child(2) {
      display: inline-block;
      width: 30px;
    }
  }
  > button {
    margin-left: 6px;
    width: 48px;
    height: 25px;
    opacity: 1;
    background: #ffffff;
    border: 1px solid #e1e0e0;
    border-radius: 2px;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #555555;
  }
}
.foot-bnt {
  text-align: center;
  > button {
    width: 160px;
    height: 44px;
    border: none;
    background: #efefef;
    margin-top: 30px;
    color: #ff004d;
  }
  .btn {
    margin-left: 20px;
    color: #ffffff;
    background: #ff004d;
  }
}
::v-deep .el-dialog {
  .el-dialog__header {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #222222;
    letter-spacing: 0px;
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .el-dialog__body {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 48px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #222222;
    letter-spacing: 0px;
  }
  .el-dialog__footer {
    padding-bottom: 60px;
    padding-top: 0px;
    .el-button {
      width: 160px;
      height: 44px;
      margin: 0px 20px;
    }
  }
}
</style>
